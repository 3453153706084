import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {AuthenticationService} from '../services/auth.service';
import {Router} from "@angular/router";
import Swal from "sweetalert2";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private authenticationService: AuthenticationService, private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        // auto logout if 401 response returned from api
        this.handleExpiredToken(err, next)
      }
      return throwError(err);
    }));
  }

  private handleExpiredToken(error: HttpErrorResponse, next: HttpHandler) {
    this.authenticationService.logout().then(x => {
      this.router.navigate(['/auth/login']);
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'You have been logged out due to inactivity. Please sign in again to continue.',
      })
      return this.refreshTokenSubject
    });
  }
}
