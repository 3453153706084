export class globalFunction {

  static isNumeric = (val: string): boolean => {
    return !isNaN(Number(val));
  }

  static hasKey(obj: any, key: string): boolean {
    return key in obj;
  }
}
