import { Component, OnInit, Input } from '@angular/core';
import { globalFunction } from '../../GlobalFunction';
import { MenuItem } from 'src/app/layouts/sidebar/menu.model';
import { NavigationEnd, Router } from '@angular/router';
import {MENU} from '../../../layouts/sidebar/menu';
import * as data from '../../../../assets/i18n/en.json';


@Component({
  selector: 'app-page-title',
  templateUrl: './pagetitle.component.html',
  styleUrls: ['./pagetitle.component.scss']
})
export class PagetitleComponent implements OnInit {

  @Input() breadcrumbItems;
  @Input() title: string;
  @Input() isMonitoring: boolean = false;
  @Input() isHideDirectioryPath: boolean = false;
  @Input() isPathDetailMonitoring: boolean = false;
  @Input() isPathDetailMedia: boolean = false;

  dataTitleName: any = null
  titleParent: any = null
  isList: boolean = false
  isMenuEdit: boolean = false
  isMenuDetail: boolean = false
  integrateBreadcrumb: any[] = []
  itemIndex = window.location.pathname;
  itemIndexParams = (location.pathname+location.search).substr(1);



  menuJson:MenuItem[] = MENU;


  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd ) {
        this.itemIndex = window.location.pathname;
        this.itemIndexParams = (location.pathname+location.search).substr(1)
        this.ngOnInit()
      }
    });
  }

  ngOnInit(): void {
    this.initializeBreadcrumb()
    // console.log('ini params',this.itemIndexParams)
  }
  // http://localhost:4200/datatable/menu
  initializeBreadcrumb() {
    this.dataTitleName = null
    // console.log(this.itemIndex)
    let removeFirstWording = this.itemIndex
    this.findSubMenuName(removeFirstWording, this.menuJson)
    let objTitleMenu = localStorage.getItem('objTitleMenu')
    if (!this.dataTitleName) {
      this.dataTitleName = JSON.parse(objTitleMenu)
    }
    this.findMenuName()
    this.checkMenuIsEdit(this.itemIndex)
  }

  findSubMenuName(pathMenu, data: MenuItem[]) {
    // console.log(this.dataTitleName)
    if (!this.dataTitleName) {
      data.forEach(menu => {
        if (menu.link) {
          if (menu.link.indexOf(pathMenu) > -1) {
            this.dataTitleName = menu
            localStorage.setItem('objTitleMenu', JSON.stringify(this.dataTitleName))
          }
        }
        if (!this.dataTitleName && menu.subItems?.length > 0) {
          this.findSubMenuName(pathMenu, menu.subItems)
        }
      })
    }
  }

 changeTitleName(pathName: string): string {

  let path = pathName
    path = path.replace("MENUITEMS.", "")
    const pathSplit = path.split(".")
    let value = JSON.parse(JSON.stringify(data))
    value = value.MENUITEMS

    for (const prop of pathSplit) {
      if (value.hasOwnProperty(prop)) {
        value = value[prop];
      } else {
        value = "-";
        break;
      }
    }
    return value
    // console.log(value)
 }

  findMenuName() {
    if (this.dataTitleName?.parentId) {
      let findHeaderMenu = this.menuJson.find(headerMenu => headerMenu.id === this.dataTitleName.parentId)
      this.titleParent = findHeaderMenu?.label
    } else {
      this.titleParent = null
    }


  }
  checkMenuIsEdit(path) {
    // console.log('ini path', path)
    // console.log('ini path 2', path.charAt(path.length -1))
    // console.log('index',this.itemIndex)
    if (path.indexOf('/monitoring/detail') > -1 || path.indexOf('/article/detail') > -1 || path.indexOf('/interaction/detail') > -1) {
      this.isMenuDetail = true
      this.isMenuEdit = false
      this.isList = false
    } else if (globalFunction.isNumeric(path[path.length - 1]) || this.itemIndexParams.indexOf('?') > -1) {
      this.isMenuDetail = false
      this.isMenuEdit = true
      this.isList = false
    } else if (path.indexOf('new') > -1 || path.indexOf('create') > -1) {
      this.isMenuDetail = false
      this.isMenuEdit = false
      this.isList = false
    } else {
      this.isList = true
    }
    this.adjustBreadCrumb(path)
  }
  // MENUITEMS.MASTERDATA.LIST.KATEGORIKONTEN
  adjustBreadCrumb(path) {
    let objTitleMenu = JSON.parse(localStorage.getItem('objTitleMenu'))
    this.integrateBreadcrumb = []
    if (objTitleMenu?.parentId) {
      this.integrateBreadcrumb.push({ label : this.titleParent})
    }
    if (this.itemIndex.indexOf('create-automation-publish') > -1) {
      this.integrateBreadcrumb.push({ label: "MENUITEMS.CREATEAUTOPUBLISH.TEXT" })
    } else {
      this.integrateBreadcrumb.push({ label: this.dataTitleName?.label })
    }
    if (this.isList) {
      this.integrateBreadcrumb.push({ label: 'Daftar', active: true})
    } else if (this.isMenuDetail) {
      if (this.isPathDetailMonitoring) {
        this.integrateBreadcrumb.push({ label: 'Detail Pencarian', active: true})
        if (this.isPathDetailMedia){
          this.integrateBreadcrumb.push({ label: 'Detail', active: true})
        }
      } else {
        this.integrateBreadcrumb.push({ label: 'Detail', active: true})
      }
    } else if (this.isMenuEdit) {
      this.integrateBreadcrumb.push({ label: 'Ubah', active: true})
    } else {
      this.integrateBreadcrumb.push({ label: 'Tambah', active: true})
    }
  }

}
