import { Injectable } from '@angular/core';

import { getFirebaseBackend } from '../../authUtils';

import { User } from '../models/auth.models';
import {HttpClient, HttpParams} from "@angular/common/http";
import {BehaviorSubject, Observable} from "rxjs";
import {map} from "rxjs/operators";
import * as jwt_decode from 'jwt-decode';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

  user: User;

  private myCurrentUserSubject: BehaviorSubject<User>;
  private myCurrentUser: Observable<User>;

    constructor(private http: HttpClient) {
      this.myCurrentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
      this.myCurrentUser = this.myCurrentUserSubject.asObservable();
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
      return this.myCurrentUserSubject.value;
        // return getFirebaseBackend().getAuthenticatedUser();
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {
        return getFirebaseBackend().loginUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, password: string) {
        return getFirebaseBackend().registerUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return getFirebaseBackend().forgetPassword(email).then((response: any) => {
            const message = response.data;
            return message;
        });
    }

    /**
     * Logout the user
     */
    logout(): Promise<boolean> {
      return new Promise((resolve, reject) => {
        localStorage.removeItem("currentUser")
        resolve(true);
      });
      // localStorage.removeItem('access_token');
      // localStorage.removeItem('id');
      // localStorage.removeItem('name');
      // localStorage.removeItem('username');
      // localStorage.removeItem('authorities');
      // localStorage.removeItem('refresh_token');
      // localStorage.removeItem('filter_dashboard');
      // this.currentUserSubject.next(null);
    }

  loginUser(username: string, password: string) {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    const clientId = "cekmedsos-app";
    const grantType = "password";
    const clientSecret = "3vkoIWVlRJ25TUVy0ALRg5JLq5n4UfIO";

    // const body = new HttpParams()
    //   .set('username', username)
    //   .set('password', password)
    //   .set('grant_type', grantType)
    //   .set('client_id', clientId)
    //   .set('client_secret', clientSecret);

    let requestBody = {
      username: username,
      password: password,
    }

    return this.http.post<any>( environment.host+ '/api/auth/login', requestBody
      ,{headers}
    ).pipe(map(data => {
      // console.log("test login ", data);
      if (data && data.data.token) {
        this.user = data.data
        this.user.id = "";
        this.user.firstName = "Admin";
        if (this.user.roleName == null) {
          this.user.roleName = "Administrator"
        }
        localStorage.setItem('currentUser', JSON.stringify(this.user));
        this.myCurrentUserSubject.next(this.user);
      }
      return this.user;
    }));
  }

  registerUser(userId: string, userName: string) {
    const headers = {
      'Content-Type': 'application/json'
    };

    const body = {
      "userId": userId,
      "name": userName
    }

    return this.http.post<any>(
      environment.host + "/api/client/register",
      body,
      {headers}
    );
  }
}

